<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill-rule="evenodd"
      d="M31,22 L10,22 L10,14.2105263 L16.558,14.2105263 L13.75,18.0831579 L15.937,19.6842105 L19.15,15.2505263 L20.5,13.3894737 L21.85,15.2505263 L25.063,19.6842105 L27.25,18.0831579 L24.442,14.2105263 L31,14.2105263 M31,29 L10,29 L10,26 L31,26 M16.45,8.73684211 C17.1955844,8.73684211 17.8,9.34950508 17.8,10.1052632 C17.8,10.8610212 17.1955844,11.4736842 16.45,11.4736842 C15.7044156,11.4736842 15.1,10.8610212 15.1,10.1052632 C15.1,9.34950508 15.7044156,8.73684211 16.45,8.73684211 M24.55,8.73684211 C25.2955844,8.73684211 25.9,9.34950508 25.9,10.1052632 C25.9,10.8610212 25.2955844,11.4736842 24.55,11.4736842 C23.8044156,11.4736842 23.2,10.8610212 23.2,10.1052632 C23.2,9.34950508 23.8044156,8.73684211 24.55,8.73684211 M31.3,11 L28.357,11 C28.5055,10.5757895 28.6,10.5842105 28.6,10.1052632 C28.6,7.83798892 26.7867532,6 24.55,6 C23.1325,6 21.904,6.73894737 21.175,7.84736842 L20.5,8.73684211 L19.825,7.83368421 C19.096,6.73894737 17.8675,6 16.45,6 C14.2132468,6 12.4,7.83798892 12.4,10.1052632 C12.4,10.5842105 12.4945,10.5757895 12.643,11 L9.7,11 C8.2015,11 7,12.6915789 7,14.2105263 L7,29.2631579 C7,30.7821053 8.2015,32 9.7,32 L31.3,32 C32.7985,32 34,30.7821053 34,29.2631579 L34,14.2105263 C34,12.6915789 32.7985,11 31.3,11 Z"
    />
  </svg>
</template>
<script>
import { BONUSES_ICON } from '@/constants'
export default {
  name: BONUSES_ICON,
}
</script>
